.head_div {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.swiper-container-banner {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.banner-video::after,
.swiper-container-banner .swiper-slide::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: block;
    width: 100%;
    height: 100%;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkUzNUIyNTRBNTAwMDExRUI5ODQwRDEwODI4RTc5MzdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkUzNUIyNTRCNTAwMDExRUI5ODQwRDEwODI4RTc5MzdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTM1QjI1NDg1MDAwMTFFQjk4NDBEMTA4MjhFNzkzN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTM1QjI1NDk1MDAwMTFFQjk4NDBEMTA4MjhFNzkzN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6BQr3IAAAAHUlEQVR42mJkYGBIY4ACJhDh4eExE0QzIssABBgALbkCQvC7tXcAAAAASUVORK5CYII=') center;
    content: "";
}

.swiper-slide {
    position: relative;
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    align-items: center;
    justify-content: center;
}

.swiper-slide .swiper-text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    margin-left: 8.5%;
    width: 100%;
    height: 100%;
}

.swiper-slide .swiper-text p {
    width: 12em;
    color: #fff;
    font-weight: bold;
    font-size: 48px;
    line-height: 1.8;
}
.swiper-slide .swiper-content-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-left: 8.5%;
    width: 40%;
    height: 100%;
}
.swiper-slide .swiper-content-box .banner-title{
    margin-top: 14px;
    margin-bottom: 25px;
    padding-bottom: 25px;
    color: #fff;
    font-weight: bold;
    font-size: 60px;
    line-height: 1.4;
}
.swiper-slide .swiper-content-box .btn-more{
    padding-right: 7px;
    border: 0;
    background-color: var(--up-orange);
}
.swiper-slide .swiper-content-box .btn-more .banner-btn-wrap{
    display: flex;
    align-items: center;
}
.swiper-slide .swiper-content-box .btn-more .banner-btn-wrap p{
    color: #fff;
    font-size: 16px;
}
.swiper-slide .swiper-content-box .btn-more .banner-btn-wrap i{
    margin: 0 7px 1px 7px;
    height: 100%;
    color: #fff;
    font-size: 24px;
    transition: all 0.3s ease;
}
.swiper-slide .swiper-content-box .btn-more:hover .banner-btn-wrap i{
    margin: 0 0 1px 14px;
}
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;

    -o-object-fit: cover;
       object-fit: cover;
}

.swiper-container-banner .swiper-button-next {
    color: #cc6736;
}

.swiper-container-banner .swiper-button-prev {
    color: #cc6736;
}

.swiper-container-banner .swiper-pagination-bullet-active {
    background-color: #cc6736;
}

@media screen and (max-width:1400px) {
    .swiper-slide .swiper-text p{
        font-size: 40px;
    }
}
@media screen and (max-width:1024px) {
    .swiper-slide .swiper-content-box .banner-title{
        font-size: 48px;
    }
}
@media screen and (max-width:992px) {
    .swiper-slide .swiper-content-box{
        margin-right: 8.5%;
        width: 80%;
    }
    .swiper-slide .swiper-content-box .banner-title{
        padding-bottom:0;
    }
}
@media screen and (max-width:768px) {
    .swiper-slide .swiper-text p{
        font-size: 36px;
    }
    .swiper-container-banner .swiper-button-next,
    .swiper-container-banner .swiper-button-prev{
        display:none;
    }
    .swiper-slide .swiper-text{
        margin-left: 32px;
    }
}

@media screen and (max-width:560px) {
    .swiper-slide .swiper-text p{
        padding: 0 10px;
        width:100%;
        text-align: center;
    }
    .swiper-slide .swiper-text{
        margin: 0;
        
    }
}
@media screen and (max-width:425px) {
    .swiper-slide .swiper-text p{
        font-size: 24px;
    }
    .swiper-slide .swiper-content-box .banner-title{
        font-size: 36px;
    }
}